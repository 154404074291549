import React from 'react';
import "./Contato.css";
import { desktopImages } from '../images';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Conteudo.css"
const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2.3, // Mostrar 2 logos e um pedaço da terceira
    slidesToScroll: 1,
    arrows: true,
    responsive: [
        {
            breakpoint: 1024,
            settings: "unslick" // Desativar carrossel em telas maiores
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 2.3,
                slidesToScroll: 1,
                margin: 0,
                variableWidth: true
            }
        }
    ]
};
const Contato = () => {
    return (
        <div id='contato'>
            <div class="body-main">
                <div class="logo-group">
                    <div class="img-test-bg"></div>
                </div>
                <h1 class="titulo-inicio">Entre em contato para conhecer as empresas do <b>Essentia Group</b>, tirar as suas
                    dúvidas e fazer os seus pedidos.</h1>

                <div class="container-1">
                    <div class="box-grupo pharma">
                        <div class="intro">
                            <a target="_blank" href="https://essentia.com.br/" rel="noreferrer">
                                <h2 class="titulo">Essentia Pharma</h2>
                            </a>
                            <h3 class="subtitulo">Atendimento para Florianópolis e região.</h3>
                        </div>
                        <div class="contatos">
                            <div class="whats-div">
                                <a target="_blank" href="https://api.whatsapp.com/send?phone=554840427087"
                                    class="whats" rel="noreferrer">(48) 4042 7087</a>
                                <a target="_blank" href="https://api.whatsapp.com/send?phone=554840427087" rel="noreferrer"><svg
                                    id="Whats_App" data-name="Whats App" xmlns="http://www.w3.org/2000/svg"
                                    xmlnsXlink="http://www.w3.org/1999/xlink" width="18.583" height="18.75"
                                    viewBox="0 0 18.583 18.75">
                                    <defs>
                                        <clipPath id="clip-path">
                                            <rect id="Retângulo_1055" data-name="Retângulo 1055" width="18.583"
                                                height="18.75" fill="none" stroke="#242222" stroke-width="0.4" />
                                        </clipPath>
                                    </defs>
                                    <g id="Grupo_1448" data-name="Grupo 1448" clip-path="url(#clip-path)">
                                        <path id="Caminho_735" data-name="Caminho 735"
                                            d="M13.737,11.209c-.228-.125-1.352-.732-1.562-.818s-.365-.13-.529.1-.63.735-.771.885-.278.165-.508.039a6.369,6.369,0,0,1-1.829-1.227A7.022,7.022,0,0,1,7.3,8.523c-.128-.238,0-.36.12-.472s.243-.264.365-.4a1.6,1.6,0,0,0,.247-.38.428.428,0,0,0,0-.409c-.054-.119-.476-1.284-.653-1.758S7,4.706,6.869,4.7s-.29-.03-.446-.036a.856.856,0,0,0-.632.268A2.614,2.614,0,0,0,4.9,6.847a4.535,4.535,0,0,0,.861,2.446,9.56,9.56,0,0,0,3.845,3.669c2.333,1.023,2.345.711,2.773.688A2.357,2.357,0,0,0,14,12.6a1.952,1.952,0,0,0,.177-1.1c-.055-.1-.208-.164-.436-.289M9.459,16.737A7.539,7.539,0,0,1,5.282,15.48l-2.919.936.949-2.824a7.589,7.589,0,1,1,6.147,3.146M9.459,0A9.137,9.137,0,0,0,1.647,13.85L0,18.75l5.05-1.619A9.131,9.131,0,1,0,9.459,0"
                                            transform="translate(0 0)" fill="#f1f2f2" stroke="#242222"
                                            stroke-width="0.4" fill-rule="evenodd" />
                                    </g>
                                </svg></a>
                            </div>
                            <p target="_blank" href="" class="phone">(48) 3322 2311</p>
                            <a target="_blank" href="mailto:atendimento@essentia.com.br"
                                class="mail" rel="noreferrer">atendimento@essentia.com.br</a>
                            <p target="_blank" href="" class="cep">R. Artista Bittencourt, nº 74 - Centro
                                Florianópolis/SC | CEP 88.020-060</p>
                        </div>
                    </div>
                    <div class="box-grupo injetaveis">
                        <div class="intro">
                            <a target="_blank" href="https://essentia.com.br/" rel="noreferrer">
                                <h2 class="titulo">Essentia Pharma</h2>
                            </a>
                            <h3 class="subtitulo">Atendimento para todo o Brasil.</h3>
                        </div>
                        <div class="contatos">
                            <p target="_blank" href="" class="phone"></p>
                            <div class="whats-div">
                                <a target="_blank" href="https://api.whatsapp.com/send?phone=554840427088"
                                    class="whats" rel="noreferrer">(48) 4042 7088</a>
                                <a target="_blank" href="https://api.whatsapp.com/send?phone=554840427088" rel="noreferrer"><svg
                                    id="Whats_App" data-name="Whats App" xmlns="http://www.w3.org/2000/svg"
                                    xmlnsXlink="http://www.w3.org/1999/xlink" width="18.583" height="18.75"
                                    viewBox="0 0 18.583 18.75">
                                    <defs>
                                        <clipPath id="clip-path">
                                            <rect id="Retângulo_1055" data-name="Retângulo 1055" width="18.583"
                                                height="18.75" fill="none" stroke="#242222" stroke-width="0.4" />
                                        </clipPath>
                                    </defs>
                                    <g id="Grupo_1448" data-name="Grupo 1448" clip-path="url(#clip-path)">
                                        <path id="Caminho_735" data-name="Caminho 735"
                                            d="M13.737,11.209c-.228-.125-1.352-.732-1.562-.818s-.365-.13-.529.1-.63.735-.771.885-.278.165-.508.039a6.369,6.369,0,0,1-1.829-1.227A7.022,7.022,0,0,1,7.3,8.523c-.128-.238,0-.36.12-.472s.243-.264.365-.4a1.6,1.6,0,0,0,.247-.38.428.428,0,0,0,0-.409c-.054-.119-.476-1.284-.653-1.758S7,4.706,6.869,4.7s-.29-.03-.446-.036a.856.856,0,0,0-.632.268A2.614,2.614,0,0,0,4.9,6.847a4.535,4.535,0,0,0,.861,2.446,9.56,9.56,0,0,0,3.845,3.669c2.333,1.023,2.345.711,2.773.688A2.357,2.357,0,0,0,14,12.6a1.952,1.952,0,0,0,.177-1.1c-.055-.1-.208-.164-.436-.289M9.459,16.737A7.539,7.539,0,0,1,5.282,15.48l-2.919.936.949-2.824a7.589,7.589,0,1,1,6.147,3.146M9.459,0A9.137,9.137,0,0,0,1.647,13.85L0,18.75l5.05-1.619A9.131,9.131,0,1,0,9.459,0"
                                            transform="translate(0 0)" fill="#f1f2f2" stroke="#242222"
                                            stroke-width="0.4" fill-rule="evenodd" />
                                    </g>
                                </svg>
                                </a>
                            </div>
                            <p target="_blank" href="" class="phone">0800 724 9099</p>
                            <a target="_blank" href="mailto:meupedido@essentia.com.br"
                                class="mail" rel="noreferrer">meupedido@essentia.com.br</a>
                            <p target="_blank" href="" class="cep">Av. Gentil Reinaldo Cordioli, nº 161
                                Palhoça - SC | CEP: 88.133-500</p>
                        </div>
                    </div>
                </div>

                <div class="container-1">
                    <div class="box-grupo pharma">
                        <div class="intro">
                            <a target="_blank" href="https://essentia.com.br/" rel="noreferrer">
                                <h2 class="titulo">Essentia Injetáveis</h2>
                            </a>
                            <h3 class="subtitulo">Faça o seu pedido.</h3>
                        </div>
                        <div class="contatos">
                            <p target="_blank" href="" class="phone"></p>
                            <div class="whats-div">
                                <a target="_blank" href="https://api.whatsapp.com/send?phone=5548988029876"
                                    class="whats" rel="noreferrer">(48) 98802 9876</a>
                                <a target="_blank" href="https://api.whatsapp.com/send?phone=5548988544819" rel="noreferrer"><svg
                                    id="Whats_App" data-name="Whats App" xmlns="http://www.w3.org/2000/svg"
                                    xmlnsXlink="http://www.w3.org/1999/xlink" width="18.583" height="18.75"
                                    viewBox="0 0 18.583 18.75">
                                    <defs>
                                        <clipPath id="clip-path">
                                            <rect id="Retângulo_1055" data-name="Retângulo 1055" width="18.583"
                                                height="18.75" fill="none" stroke="#242222" stroke-width="0.4" />
                                        </clipPath>
                                    </defs>
                                    <g id="Grupo_1448" data-name="Grupo 1448" clip-path="url(#clip-path)">
                                        <path id="Caminho_735" data-name="Caminho 735"
                                            d="M13.737,11.209c-.228-.125-1.352-.732-1.562-.818s-.365-.13-.529.1-.63.735-.771.885-.278.165-.508.039a6.369,6.369,0,0,1-1.829-1.227A7.022,7.022,0,0,1,7.3,8.523c-.128-.238,0-.36.12-.472s.243-.264.365-.4a1.6,1.6,0,0,0,.247-.38.428.428,0,0,0,0-.409c-.054-.119-.476-1.284-.653-1.758S7,4.706,6.869,4.7s-.29-.03-.446-.036a.856.856,0,0,0-.632.268A2.614,2.614,0,0,0,4.9,6.847a4.535,4.535,0,0,0,.861,2.446,9.56,9.56,0,0,0,3.845,3.669c2.333,1.023,2.345.711,2.773.688A2.357,2.357,0,0,0,14,12.6a1.952,1.952,0,0,0,.177-1.1c-.055-.1-.208-.164-.436-.289M9.459,16.737A7.539,7.539,0,0,1,5.282,15.48l-2.919.936.949-2.824a7.589,7.589,0,1,1,6.147,3.146M9.459,0A9.137,9.137,0,0,0,1.647,13.85L0,18.75l5.05-1.619A9.131,9.131,0,1,0,9.459,0"
                                            transform="translate(0 0)" fill="#f1f2f2" stroke="#242222"
                                            stroke-width="0.4" fill-rule="evenodd" />
                                    </g>
                                </svg></a>
                            </div>
                            <a target="_blank" href="mailto:injetaveis@essentia.com.br"
                                class="mail" rel="noreferrer">injetaveis@essentia.com.br</a>
                        </div>
                    </div>
                    <div class="box-grupo nutrition">
                        <div class="intro">
                            <a target="_blank" href="https://essentia.com.br/" rel="noreferrer">
                                <h2 class="titulo">Consultoria técnica
                                    <br />farmacêutica
                                </h2>
                            </a>
                            <h3 class="subtitulo">Tire suas dúvidas.</h3>
                        </div>
                        <div class="contatos">
                            <div class="whats-div">
                                <a target="_blank" href="https://api.whatsapp.com/send?phone=5548988076983"
                                    class="whats" rel="noreferrer">(48) 98854 4819</a>
                                <a target="_blank" href="https://api.whatsapp.com/send?phone=5548988076983" rel="noreferrer"><svg
                                    id="Whats_App" data-name="Whats App" xmlns="http://www.w3.org/2000/svg"
                                    xmlnsXlink="http://www.w3.org/1999/xlink" width="18.583" height="18.75"
                                    viewBox="0 0 18.583 18.75">
                                    <defs>
                                        <clipPath id="clip-path">
                                            <rect id="Retângulo_1055" data-name="Retângulo 1055" width="18.583"
                                                height="18.75" fill="none" stroke="#242222" stroke-width="0.4" />
                                        </clipPath>
                                    </defs>
                                    <g id="Grupo_1448" data-name="Grupo 1448" clip-path="url(#clip-path)">
                                        <path id="Caminho_735" data-name="Caminho 735"
                                            d="M13.737,11.209c-.228-.125-1.352-.732-1.562-.818s-.365-.13-.529.1-.63.735-.771.885-.278.165-.508.039a6.369,6.369,0,0,1-1.829-1.227A7.022,7.022,0,0,1,7.3,8.523c-.128-.238,0-.36.12-.472s.243-.264.365-.4a1.6,1.6,0,0,0,.247-.38.428.428,0,0,0,0-.409c-.054-.119-.476-1.284-.653-1.758S7,4.706,6.869,4.7s-.29-.03-.446-.036a.856.856,0,0,0-.632.268A2.614,2.614,0,0,0,4.9,6.847a4.535,4.535,0,0,0,.861,2.446,9.56,9.56,0,0,0,3.845,3.669c2.333,1.023,2.345.711,2.773.688A2.357,2.357,0,0,0,14,12.6a1.952,1.952,0,0,0,.177-1.1c-.055-.1-.208-.164-.436-.289M9.459,16.737A7.539,7.539,0,0,1,5.282,15.48l-2.919.936.949-2.824a7.589,7.589,0,1,1,6.147,3.146M9.459,0A9.137,9.137,0,0,0,1.647,13.85L0,18.75l5.05-1.619A9.131,9.131,0,1,0,9.459,0"
                                            transform="translate(0 0)" fill="#f1f2f2" stroke="#242222"
                                            stroke-width="0.4" fill-rule="evenodd" />
                                    </g>
                                </svg></a>

                            </div>
                            <a target="_blank" href="mailto:consultoria@essentia.com.br"
                                class="mail" rel="noreferrer">consultoria@essentia.com.br</a>
                        </div>
                    </div>
                </div>
                <div class="container-1">
                    <div class="box-grupo nutrition">
                        <div class="intro">
                            <a target="_blank" href="https://www.essentialnutrition.com.br/" rel="noreferrer">
                                <h2 class="titulo">Essential Nutrition</h2>
                            </a>
                            <h3 class="subtitulo">Faça o seu pedido.</h3>
                        </div>
                        <div class="contatos">
                            <div class="whats-div">
                                <a target="_blank" href="https://api.whatsapp.com/send?phone=5548988076983"
                                    class="whats" rel="noreferrer">(48) 98807 6983</a>
                                <a target="_blank" href="https://api.whatsapp.com/send?phone=5548988076983" rel="noreferrer"><svg
                                    id="Whats_App" data-name="Whats App" xmlns="http://www.w3.org/2000/svg"
                                    xmlnsXlink="http://www.w3.org/1999/xlink" width="18.583" height="18.75"
                                    viewBox="0 0 18.583 18.75">
                                    <defs>
                                        <clipPath id="clip-path">
                                            <rect id="Retângulo_1055" data-name="Retângulo 1055" width="18.583"
                                                height="18.75" fill="none" stroke="#242222" stroke-width="0.4" />
                                        </clipPath>
                                    </defs>
                                    <g id="Grupo_1448" data-name="Grupo 1448" clip-path="url(#clip-path)">
                                        <path id="Caminho_735" data-name="Caminho 735"
                                            d="M13.737,11.209c-.228-.125-1.352-.732-1.562-.818s-.365-.13-.529.1-.63.735-.771.885-.278.165-.508.039a6.369,6.369,0,0,1-1.829-1.227A7.022,7.022,0,0,1,7.3,8.523c-.128-.238,0-.36.12-.472s.243-.264.365-.4a1.6,1.6,0,0,0,.247-.38.428.428,0,0,0,0-.409c-.054-.119-.476-1.284-.653-1.758S7,4.706,6.869,4.7s-.29-.03-.446-.036a.856.856,0,0,0-.632.268A2.614,2.614,0,0,0,4.9,6.847a4.535,4.535,0,0,0,.861,2.446,9.56,9.56,0,0,0,3.845,3.669c2.333,1.023,2.345.711,2.773.688A2.357,2.357,0,0,0,14,12.6a1.952,1.952,0,0,0,.177-1.1c-.055-.1-.208-.164-.436-.289M9.459,16.737A7.539,7.539,0,0,1,5.282,15.48l-2.919.936.949-2.824a7.589,7.589,0,1,1,6.147,3.146M9.459,0A9.137,9.137,0,0,0,1.647,13.85L0,18.75l5.05-1.619A9.131,9.131,0,1,0,9.459,0"
                                            transform="translate(0 0)" fill="#f1f2f2" stroke="#242222"
                                            stroke-width="0.4" fill-rule="evenodd" />
                                    </g>
                                </svg></a>

                            </div>
                            <p target="_blank" href="" class="phone">0800 607 9030</p>
                            <a target="_blank" href="mailto:contato@essentialnutrition.com.br"
                                class="mail" rel="noreferrer">contato@essentialnutrition.com.br</a>
                        </div>
                    </div>
                    <div class="box-grupo nutrition">
                        <div class="intro">
                            <a target="_blank" href="https://hub.essentialnutrition.com.br/institucional" rel="noreferrer">
                                <h2 class="titulo">Essential Nutrition</h2>
                            </a>
                            <h3 class="subtitulo">Seja um revendedor credenciado.</h3>
                        </div>
                        <div class="contatos">
                            <div class="whats-div">
                                <a target="_blank" href="https://api.whatsapp.com/send?phone=5548988076983"
                                    class="whats" rel="noreferrer">(48) 98807 6983</a>
                                <a target="_blank" href="https://api.whatsapp.com/send?phone=5548988076983" rel="noreferrer"><svg
                                    id="Whats_App" data-name="Whats App" xmlns="http://www.w3.org/2000/svg"
                                    xmlnsXlink="http://www.w3.org/1999/xlink" width="18.583" height="18.75"
                                    viewBox="0 0 18.583 18.75">
                                    <defs>
                                        <clipPath id="clip-path">
                                            <rect id="Retângulo_1055" data-name="Retângulo 1055" width="18.583"
                                                height="18.75" fill="none" stroke="#242222" stroke-width="0.4" />
                                        </clipPath>
                                    </defs>
                                    <g id="Grupo_1448" data-name="Grupo 1448" clip-path="url(#clip-path)">
                                        <path id="Caminho_735" data-name="Caminho 735"
                                            d="M13.737,11.209c-.228-.125-1.352-.732-1.562-.818s-.365-.13-.529.1-.63.735-.771.885-.278.165-.508.039a6.369,6.369,0,0,1-1.829-1.227A7.022,7.022,0,0,1,7.3,8.523c-.128-.238,0-.36.12-.472s.243-.264.365-.4a1.6,1.6,0,0,0,.247-.38.428.428,0,0,0,0-.409c-.054-.119-.476-1.284-.653-1.758S7,4.706,6.869,4.7s-.29-.03-.446-.036a.856.856,0,0,0-.632.268A2.614,2.614,0,0,0,4.9,6.847a4.535,4.535,0,0,0,.861,2.446,9.56,9.56,0,0,0,3.845,3.669c2.333,1.023,2.345.711,2.773.688A2.357,2.357,0,0,0,14,12.6a1.952,1.952,0,0,0,.177-1.1c-.055-.1-.208-.164-.436-.289M9.459,16.737A7.539,7.539,0,0,1,5.282,15.48l-2.919.936.949-2.824a7.589,7.589,0,1,1,6.147,3.146M9.459,0A9.137,9.137,0,0,0,1.647,13.85L0,18.75l5.05-1.619A9.131,9.131,0,1,0,9.459,0"
                                            transform="translate(0 0)" fill="#f1f2f2" stroke="#242222"
                                            stroke-width="0.4" fill-rule="evenodd" />
                                    </g>
                                </svg></a>

                            </div>
                            <p target="_blank" href="" class="phone">0800 607 9030</p>
                            <a target="_blank" href="mailto:revendas@essentialnutrition.com.br"
                                class="mail" rel="noreferrer">revendas@essentialnutrition.com.br</a>
                        </div>
                    </div>
                </div>
                <div class="container-1">
                    <div class="box-grupo pharma">
                        <div class="intro">
                            <a target="_blank" href="https://www.noorskin.com.br/" rel="noreferrer">
                                <h2 class="titulo">Noorskin </h2>
                            </a>
                            <h3 class="subtitulo">Conheça a nossa linha de skincare.</h3>
                        </div>
                        <div class="contatos">
                            <div class="whats-div">
                                <a target="_blank" href="https://api.whatsapp.com/send?phone=5548988583701"
                                    class="whats" rel="noreferrer">(48)
                                    98858 3701</a>
                                <a target="_blank" href="https://api.whatsapp.com/send?phone=5548988583701" rel="noreferrer"><svg
                                    id="Whats_App" data-name="Whats App" xmlns="http://www.w3.org/2000/svg"
                                    xmlnsXlink="http://www.w3.org/1999/xlink" width="18.583" height="18.75"
                                    viewBox="0 0 18.583 18.75">
                                    <defs>
                                        <clipPath id="clip-path">
                                            <rect id="Retângulo_1055" data-name="Retângulo 1055" width="18.583"
                                                height="18.75" fill="none" stroke="#242222" stroke-width="0.4" />
                                        </clipPath>
                                    </defs>
                                    <g id="Grupo_1448" data-name="Grupo 1448" clip-path="url(#clip-path)">
                                        <path id="Caminho_735" data-name="Caminho 735"
                                            d="M13.737,11.209c-.228-.125-1.352-.732-1.562-.818s-.365-.13-.529.1-.63.735-.771.885-.278.165-.508.039a6.369,6.369,0,0,1-1.829-1.227A7.022,7.022,0,0,1,7.3,8.523c-.128-.238,0-.36.12-.472s.243-.264.365-.4a1.6,1.6,0,0,0,.247-.38.428.428,0,0,0,0-.409c-.054-.119-.476-1.284-.653-1.758S7,4.706,6.869,4.7s-.29-.03-.446-.036a.856.856,0,0,0-.632.268A2.614,2.614,0,0,0,4.9,6.847a4.535,4.535,0,0,0,.861,2.446,9.56,9.56,0,0,0,3.845,3.669c2.333,1.023,2.345.711,2.773.688A2.357,2.357,0,0,0,14,12.6a1.952,1.952,0,0,0,.177-1.1c-.055-.1-.208-.164-.436-.289M9.459,16.737A7.539,7.539,0,0,1,5.282,15.48l-2.919.936.949-2.824a7.589,7.589,0,1,1,6.147,3.146M9.459,0A9.137,9.137,0,0,0,1.647,13.85L0,18.75l5.05-1.619A9.131,9.131,0,1,0,9.459,0"
                                            transform="translate(0 0)" fill="#f1f2f2" stroke="#242222"
                                            stroke-width="0.4" fill-rule="evenodd" />
                                    </g>
                                </svg></a>
                            </div>
                            <p target="_blank" href="" class="phone">0800 777 9100</p>
                            <a target="_blank" href="mailto:contato@noorskin.com.br"
                                class="mail" rel="noreferrer">contato@noorskin.com.br</a>
                        </div>
                    </div>

                    <div class="box-grupo injetaveis">
                        <div class="intro">
                            <a target="_blank"
                                href="https://begenerous.com.br/?utm_source=essential-nutrition&utm_medium=super-menu&utm_campaign=lancamento" rel="noreferrer">
                                <h2 class="titulo">Be Generous</h2>
                            </a>
                            <h3 class="subtitulo">Saiba mais sobre o<br /> nosso projeto social.</h3>
                        </div>
                        <div class="contatos">
                            <p target="_blank" href="" class="phone"></p>
                            <div class="whats-div">
                                <a target="_blank" href="https://api.whatsapp.com/send?phone=5548988183492"
                                    class="whats" rel="noreferrer">(48)
                                    98818 3492</a>
                                <a target="_blank" href="https://api.whatsapp.com/send?phone=5548988183492" rel="noreferrer"><svg
                                    id="Whats_App" data-name="Whats App" xmlns="http://www.w3.org/2000/svg"
                                    xmlnsXlink="http://www.w3.org/1999/xlink" width="18.583" height="18.75"
                                    viewBox="0 0 18.583 18.75">
                                    <defs>
                                        <clipPath id="clip-path">
                                            <rect id="Retângulo_1055" data-name="Retângulo 1055" width="18.583"
                                                height="18.75" fill="none" stroke="#242222" stroke-width="0.4" />
                                        </clipPath>
                                    </defs>
                                    <g id="Grupo_1448" data-name="Grupo 1448" clip-path="url(#clip-path)">
                                        <path id="Caminho_735" data-name="Caminho 735"
                                            d="M13.737,11.209c-.228-.125-1.352-.732-1.562-.818s-.365-.13-.529.1-.63.735-.771.885-.278.165-.508.039a6.369,6.369,0,0,1-1.829-1.227A7.022,7.022,0,0,1,7.3,8.523c-.128-.238,0-.36.12-.472s.243-.264.365-.4a1.6,1.6,0,0,0,.247-.38.428.428,0,0,0,0-.409c-.054-.119-.476-1.284-.653-1.758S7,4.706,6.869,4.7s-.29-.03-.446-.036a.856.856,0,0,0-.632.268A2.614,2.614,0,0,0,4.9,6.847a4.535,4.535,0,0,0,.861,2.446,9.56,9.56,0,0,0,3.845,3.669c2.333,1.023,2.345.711,2.773.688A2.357,2.357,0,0,0,14,12.6a1.952,1.952,0,0,0,.177-1.1c-.055-.1-.208-.164-.436-.289M9.459,16.737A7.539,7.539,0,0,1,5.282,15.48l-2.919.936.949-2.824a7.589,7.589,0,1,1,6.147,3.146M9.459,0A9.137,9.137,0,0,0,1.647,13.85L0,18.75l5.05-1.619A9.131,9.131,0,1,0,9.459,0"
                                            transform="translate(0 0)" fill="#f1f2f2" stroke="#242222"
                                            stroke-width="0.4" fill-rule="evenodd" />
                                    </g>
                                </svg></a>

                            </div>
                            <a target="_blank" href="mailto:contato@begenerous.com.br"
                                class="mail" rel="noreferrer">contato@begenerous.com.br</a>
                        </div>
                    </div>
                    <div class="box-grupo nutrition">
                        <div class="intro">
                            <a target="_blank" href="https://easy-health.app/landing-page/index.html" rel="noreferrer">
                                <h2 class="titulo">Easy Health</h2>
                            </a>
                            <h3 class="subtitulo">Seu software de gestão<br />
                                clínica completo e definitivo.</h3>
                        </div>
                        <div class="contatos">
                            <div class="whats-div">
                                <p target="_blank" href="" class="phone"></p>
                                <a target="_blank" href="https://api.whatsapp.com/send?phone=5548988172234"
                                    class="whats" rel="noreferrer">(48)
                                    98817 2234</a>
                                <a target="_blank" href="https://api.whatsapp.com/send?phone=5548988172234" rel="noreferrer"><svg
                                    id="Whats_App" data-name="Whats App" xmlns="http://www.w3.org/2000/svg"
                                    xmlnsXlink="http://www.w3.org/1999/xlink" width="18.583" height="18.75"
                                    viewBox="0 0 18.583 18.75">
                                    <defs>
                                        <clipPath id="clip-path">
                                            <rect id="Retângulo_1055" data-name="Retângulo 1055" width="18.583"
                                                height="18.75" fill="none" stroke="#242222" stroke-width="0.4" />
                                        </clipPath>
                                    </defs>
                                    <g id="Grupo_1448" data-name="Grupo 1448" clip-path="url(#clip-path)">
                                        <path id="Caminho_735" data-name="Caminho 735"
                                            d="M13.737,11.209c-.228-.125-1.352-.732-1.562-.818s-.365-.13-.529.1-.63.735-.771.885-.278.165-.508.039a6.369,6.369,0,0,1-1.829-1.227A7.022,7.022,0,0,1,7.3,8.523c-.128-.238,0-.36.12-.472s.243-.264.365-.4a1.6,1.6,0,0,0,.247-.38.428.428,0,0,0,0-.409c-.054-.119-.476-1.284-.653-1.758S7,4.706,6.869,4.7s-.29-.03-.446-.036a.856.856,0,0,0-.632.268A2.614,2.614,0,0,0,4.9,6.847a4.535,4.535,0,0,0,.861,2.446,9.56,9.56,0,0,0,3.845,3.669c2.333,1.023,2.345.711,2.773.688A2.357,2.357,0,0,0,14,12.6a1.952,1.952,0,0,0,.177-1.1c-.055-.1-.208-.164-.436-.289M9.459,16.737A7.539,7.539,0,0,1,5.282,15.48l-2.919.936.949-2.824a7.589,7.589,0,1,1,6.147,3.146M9.459,0A9.137,9.137,0,0,0,1.647,13.85L0,18.75l5.05-1.619A9.131,9.131,0,1,0,9.459,0"
                                            transform="translate(0 0)" fill="#f1f2f2" stroke="#242222"
                                            stroke-width="0.4" fill-rule="evenodd" />
                                    </g>
                                </svg></a>

                            </div>
                            <a target="_blank" href="mailto:suporte@easy-health.app"
                                class="mail" rel="noreferrer">suporte@easy-health.app</a>
                        </div>
                    </div>
                </div>
            </div>
                <div className='logos-grupo logos-desk'>
                <a target="_blank" href="https://essentia.com.br/" rel="noreferrer">
                    <img src={desktopImages.EssentiaPharma} alt="Essentia Pharma" />
                </a>
                <a target="_blank" href="https://www.essentialnutrition.com.br/" rel="noreferrer">
                    <img src={desktopImages.EssentialNutrition} alt="Essential Nutrition" />
                </a>
                <a target="_blank" href="https://www.noorskin.com.br/" rel="noreferrer">
                    <img className='logo-noorskin' src={desktopImages.Noorskin} alt="Noorskin" />
                </a>
                <a target="_blank"
                    href="https://begenerous.com.br/" rel="noreferrer">
                    <img width="120px" height="60px" src={desktopImages.BeGenerous} alt="Be Generous" />
                </a>
                <a href="https://easy-health.app/landing-page/index.html" target="_blank" rel="noreferrer">
                    <img width="128px" height="80px" src={desktopImages.EasyHealth} alt="Easy Health" />
                </a>
            </div>
            <Slider {...settings} className='mobile-logos-grupo'>
                <div><a target="_blank" href="https://essentia.com.br/" rel="noreferrer">
                    <img src={desktopImages.EssentiaPharma} alt="Essentia Pharma" />
                </a></div>
                <div><a target="_blank" href="https://www.essentialnutrition.com.br/" rel="noreferrer">
                    <img src={desktopImages.EssentialNutrition} alt="Essential Nutrition" />
                </a></div>
                <div><a target="_blank" href="https://www.noorskin.com.br/" rel="noreferrer">
                    <img className='logo-noorskin' src={desktopImages.Noorskin} alt="Noorskin" />
                </a></div>
                <div><a target="_blank"
                    href="https://begenerous.com.br/" rel="noreferrer">
                    <img width="120px" height="60px" src={desktopImages.BeGenerous} alt="Be Generous" />
                </a></div>
                <div><a href="https://easy-health.app/landing-page/index.html" target="_blank" rel="noreferrer">
                    <img width="128px" height="80px" src={desktopImages.EasyHealth} alt="Easy Health" />
                </a></div>
            </Slider>
        </div>
    );
}

export default Contato;